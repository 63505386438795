/* You can add global styles to this file, and also import other style files */
.invisible {
  opacity: 0;
  visibility: hidden;
}

html {
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: #c4bbab;
  color: #3f51b5;
  --windowheight: 100vh;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

@media (max-width: 1600px) {
  .minwidth-m {
    display: none !important;
  }
}

@media (min-width: 1601px) {
  .maxwidth-m {
    display: none !important;
  }
}

@media (max-width: 1024px) {
  .minwidth-s {
    display: none !important;
  }
}

@media (min-width: 1025px) {
  .maxwidth-s {
    display: none !important;
  }
}

@media (max-width: 512px) {
  .minwidth-xs {
    display: none !important;
  }
}

@media (min-width: 513px) {
  .maxwidth-xs {
    display: none !important;
  }
}

* {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  box-sizing: border-box;
  color: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Pacifico";
  src: url("/assets/typography/Pacifico/Pacifico-Regular.ttf");
}
.text-large {
  font-size: 2rem;
}

.text-medium {
  font-size: 1rem;
}

.text-small {
  font-size: 0.75rem;
}

@media (max-width: 1600px) {
  .minwidth-m {
    display: none !important;
  }
}
@media (min-width: 1601px) {
  .maxwidth-m {
    display: none !important;
  }
}
@media (max-width: 1024px) {
  .minwidth-s {
    display: none !important;
  }
}
@media (min-width: 1025px) {
  .maxwidth-s {
    display: none !important;
  }
}
@media (max-width: 512px) {
  .minwidth-xs {
    display: none !important;
  }
}
@media (min-width: 513px) {
  .maxwidth-xs {
    display: none !important;
  }
}
@media (max-width: 512px) {
  .cdk-overlay-pane {
    max-width: 100vw !important;
    width: 100vw !important;
  }
  .mat-mdc-dialog-container {
    max-width: 100vw !important;
    width: 100vw !important;
    max-height: var(--windowheight) !important;
    height: var(--windowheight) !important;
  }
}
.ng-animating .mat-expansion-panel-content {
  height: 0px;
  visibility: hidden;
}

app-user-menu .mdc-list-item__primary-text {
  color: #3f51b5;
  font-weight: bold !important;
}